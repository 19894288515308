import { sofBaseGraphqlApi } from './sofBaseGraphqlApi';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  BigDecimal: { input: any; output: any };
  Long: { input: any; output: any };
};

export type AddEventCommentRequestDto = {
  comment: Scalars['String']['input'];
  createdBy: Scalars['String']['input'];
  involvement: Involvement;
  portCallId: Scalars['Long']['input'];
  sofEventId: Scalars['Long']['input'];
};

export type AddEventRemarksRequestDto = {
  actorId: Scalars['String']['input'];
  actorName: Scalars['String']['input'];
  eventId: Scalars['Long']['input'];
  persona: Scalars['String']['input'];
  portCallId: Scalars['Long']['input'];
  text: Scalars['String']['input'];
};

export type AddEventRequestDto = {
  actor: Scalars['String']['input'];
  actorId: Scalars['String']['input'];
  eventTemplateCode: Scalars['String']['input'];
  involvement: Involvement;
  notes?: InputMaybe<Scalars['String']['input']>;
  portCallId: Scalars['Long']['input'];
  timestamp?: InputMaybe<Scalars['String']['input']>;
};

export type AddGeneralRemarksRequestDto = {
  actorId: Scalars['String']['input'];
  actorName: Scalars['String']['input'];
  code?: InputMaybe<Scalars['String']['input']>;
  persona: Scalars['String']['input'];
  portCallId: Scalars['Long']['input'];
  text: Scalars['String']['input'];
};

export type AddParametersRequestDto = {
  actor: Scalars['String']['input'];
  actorId: Scalars['String']['input'];
  cargoQuantity?: InputMaybe<CargoQuantity>;
  pilots?: InputMaybe<Pilots>;
  portCallId: Scalars['Long']['input'];
  tugsIn?: InputMaybe<Tugs>;
  tugsOut?: InputMaybe<Tugs>;
};

export type AddSofCommentRequestDto = {
  comment: Scalars['String']['input'];
  involvement: Involvement;
  portCallId: Scalars['Long']['input'];
};

export type Attachment = {
  __typename?: 'Attachment';
  actorId: Scalars['String']['output'];
  contentLength: Scalars['Long']['output'];
  contentType: Scalars['String']['output'];
  createdOn: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  md5Checksum: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type CargoQuantity = {
  actual?: InputMaybe<Scalars['Float']['input']>;
  nominal?: InputMaybe<Scalars['Float']['input']>;
};

export type Collaborator = {
  __typename?: 'Collaborator';
  hasBeenOpened: Scalars['Boolean']['output'];
  involvement: Involvement;
};

export type EditEventRemarksRequestDto = {
  id: Scalars['Long']['input'];
  portCallId: Scalars['Long']['input'];
  text: Scalars['String']['input'];
};

export type EditEventRequestDto = {
  actor: Scalars['String']['input'];
  actorId: Scalars['String']['input'];
  involvement: Involvement;
  portCallId: Scalars['Long']['input'];
  sofEventId: Scalars['Long']['input'];
  timestamp: Scalars['String']['input'];
};

export type EditSofRemarkRequestDto = {
  id: Scalars['Long']['input'];
  portCallId: Scalars['Long']['input'];
  text: Scalars['String']['input'];
};

export type Errors = {
  __typename?: 'Errors';
  eventError?: Maybe<Scalars['Boolean']['output']>;
  eventPlaceHolderError?: Maybe<Scalars['Boolean']['output']>;
  parameterError?: Maybe<Scalars['Boolean']['output']>;
  remarkError?: Maybe<Scalars['Boolean']['output']>;
};

export type EventTemplate = {
  __typename?: 'EventTemplate';
  code: Scalars['String']['output'];
  description: Scalars['String']['output'];
  mandatory: Scalars['Boolean']['output'];
};

export type GroupedEventTemplates = {
  __typename?: 'GroupedEventTemplates';
  eventTemplates: Array<EventTemplate>;
  group: Scalars['String']['output'];
};

export enum Involvement {
  Agent = 'AGENT',
  Automatic = 'AUTOMATIC',
  Charterer = 'CHARTERER',
  Consignor = 'CONSIGNOR',
  Disponent = 'DISPONENT',
  Harbour = 'HARBOUR',
  Inspector = 'INSPECTOR',
  Master = 'MASTER',
  Owner = 'OWNER',
  Port = 'PORT',
  Receiver = 'RECEIVER',
  Surveyor = 'SURVEYOR'
}

export type Mutation = {
  __typename?: 'Mutation';
  addEvent: Sof;
  addEventComment: SofEventNote;
  addGeneralRemarks: Sof;
  addParameters: Sof;
  addRemarksOnEvent: SofEventRemarkResponseDto;
  addSofComment: Array<SofComment>;
  deleteEvent: SofEvent;
  deleteEventRemark: SofEventRemarkResponseDto;
  deleteRemark: Sof;
  editEvent: Sof;
  editEventRemarks: SofEventRemarkResponseDto;
  editSofRemark: SofRemarkResponseDto;
};

export type MutationAddEventArgs = {
  addEventRequestDto: AddEventRequestDto;
};

export type MutationAddEventCommentArgs = {
  addEventCommentRequestDto: AddEventCommentRequestDto;
};

export type MutationAddGeneralRemarksArgs = {
  addGeneralRemarksRequestDto: AddGeneralRemarksRequestDto;
};

export type MutationAddParametersArgs = {
  addParametersRequestDto: AddParametersRequestDto;
};

export type MutationAddRemarksOnEventArgs = {
  addEventRemarksRequestDto: AddEventRemarksRequestDto;
};

export type MutationAddSofCommentArgs = {
  addSofCommentRequestDto: AddSofCommentRequestDto;
};

export type MutationDeleteEventArgs = {
  actor: Scalars['String']['input'];
  eventId: Scalars['ID']['input'];
  portCallId: Scalars['ID']['input'];
};

export type MutationDeleteEventRemarkArgs = {
  portCallId: Scalars['ID']['input'];
  sofEventRemarkId: Scalars['ID']['input'];
};

export type MutationDeleteRemarkArgs = {
  portCallId: Scalars['ID']['input'];
  remarkId: Scalars['ID']['input'];
};

export type MutationEditEventArgs = {
  editEventRequestDto: EditEventRequestDto;
};

export type MutationEditEventRemarksArgs = {
  editEventRemarksRequestDto: EditEventRemarksRequestDto;
};

export type MutationEditSofRemarkArgs = {
  editSofRemarkRequestDto: EditSofRemarkRequestDto;
};

export type Parameters = {
  __typename?: 'Parameters';
  cargoQuantityActual?: Maybe<Scalars['BigDecimal']['output']>;
  cargoQuantityNominal?: Maybe<Scalars['BigDecimal']['output']>;
  inboundTugsDetails?: Maybe<Scalars['String']['output']>;
  inboundTugsOrdered?: Maybe<Scalars['Int']['output']>;
  inboundTugsUsed?: Maybe<Scalars['Int']['output']>;
  outboundTugsDetails?: Maybe<Scalars['String']['output']>;
  outboundTugsOrdered?: Maybe<Scalars['Int']['output']>;
  outboundTugsUsed?: Maybe<Scalars['Int']['output']>;
  pilotsIn?: Maybe<Scalars['Int']['output']>;
  pilotsOut?: Maybe<Scalars['Int']['output']>;
};

export type Pilots = {
  in?: InputMaybe<Scalars['Int']['input']>;
  out?: InputMaybe<Scalars['Int']['input']>;
};

export type Query = {
  __typename?: 'Query';
  getActivityTimeline: Array<SofEventAction>;
  getEventComments: Array<SofEventNote>;
  /**  Event Template # */
  getEventTemplates: Array<GroupedEventTemplates>;
  /**  SOF # */
  getSofsForPortCallIds: Array<Sof>;
  getSuggestedEventTemplates: Array<EventTemplate>;
  getSuggestedRemarks: Array<SofRemarkSuggestion>;
};

export type QueryGetActivityTimelineArgs = {
  portCallId: Scalars['ID']['input'];
};

export type QueryGetEventCommentsArgs = {
  eventId: Scalars['ID']['input'];
  portCallId: Scalars['ID']['input'];
};

export type QueryGetEventTemplatesArgs = {
  portCallId: Scalars['ID']['input'];
};

export type QueryGetSofsForPortCallIdsArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type QueryGetSuggestedEventTemplatesArgs = {
  portCallId: Scalars['ID']['input'];
};

export type QueryGetSuggestedRemarksArgs = {
  portCallId: Scalars['ID']['input'];
};

export type Sof = {
  __typename?: 'Sof';
  attachments: Array<Attachment>;
  collaborators: Array<Collaborator>;
  comments: Array<SofComment>;
  error?: Maybe<Errors>;
  events: Array<SofEvent>;
  numberOfResendsToMaster?: Maybe<Scalars['Int']['output']>;
  parameters: Parameters;
  portCallId: Scalars['ID']['output'];
  remarks: Array<SofRemarkResponseDto>;
  signatureTimestamp?: Maybe<Scalars['String']['output']>;
  status: SofStatus;
};

export type SofComment = {
  __typename?: 'SofComment';
  comment: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  involvement: Involvement;
};

export type SofEvent = {
  __typename?: 'SofEvent';
  actorId?: Maybe<Scalars['String']['output']>;
  attachments: Array<Attachment>;
  comments: Array<SofEventNote>;
  creator: Scalars['String']['output'];
  description: Scalars['String']['output'];
  editable: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  involvement: Scalars['String']['output'];
  remarks: Array<SofEventRemarkResponseDto>;
  templateCode: Scalars['String']['output'];
  timestamp?: Maybe<Scalars['String']['output']>;
};

export type SofEventAction = {
  __typename?: 'SofEventAction';
  actor: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['Long']['output'];
  timestampInPortLocalTime: Scalars['String']['output'];
  type: SofEventActionType;
};

export enum SofEventActionType {
  Add = 'ADD',
  Comment = 'COMMENT',
  Delete = 'DELETE',
  Edit = 'EDIT',
  EventRemarkAddition = 'EVENT_REMARK_ADDITION',
  Resend = 'RESEND'
}

export type SofEventNote = {
  __typename?: 'SofEventNote';
  comment: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type SofEventRemarkResponseDto = {
  __typename?: 'SofEventRemarkResponseDto';
  actorId: Scalars['String']['output'];
  actorName: Scalars['String']['output'];
  createdOn: Scalars['String']['output'];
  id: Scalars['Long']['output'];
  persona: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

export type SofRemarkResponseDto = {
  __typename?: 'SofRemarkResponseDto';
  actorId: Scalars['String']['output'];
  actorName: Scalars['String']['output'];
  code?: Maybe<Scalars['String']['output']>;
  createdBy: Scalars['String']['output'];
  id: Scalars['Long']['output'];
  text: Scalars['String']['output'];
  timestamp: Scalars['String']['output'];
};

export type SofRemarkSuggestion = {
  __typename?: 'SofRemarkSuggestion';
  code: Scalars['String']['output'];
  id: Scalars['Long']['output'];
  isEditable: Scalars['Boolean']['output'];
  isMandatory: Scalars['Boolean']['output'];
  text: Scalars['String']['output'];
};

export enum SofStatus {
  Available = 'AVAILABLE',
  Hidden = 'HIDDEN',
  InProgress = 'IN_PROGRESS',
  NotAvailable = 'NOT_AVAILABLE',
  Submitted = 'SUBMITTED'
}

export type Tugs = {
  details?: InputMaybe<Scalars['String']['input']>;
  ordered?: InputMaybe<Scalars['Int']['input']>;
  used?: InputMaybe<Scalars['Int']['input']>;
};

export type GetSofStatusesQueryVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type GetSofStatusesQuery = {
  __typename?: 'Query';
  getSofsForPortCallIds: Array<{
    __typename?: 'Sof';
    portCallId: string;
    status: SofStatus;
  }>;
};

export type GetSofsQueryVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type GetSofsQuery = {
  __typename?: 'Query';
  getSofsForPortCallIds: Array<{
    __typename?: 'Sof';
    portCallId: string;
    status: SofStatus;
    numberOfResendsToMaster?: number | null;
    signatureTimestamp?: string | null;
    events: Array<{
      __typename?: 'SofEvent';
      timestamp?: string | null;
      creator: string;
      involvement: string;
      description: string;
      editable: boolean;
      templateCode: string;
      id: string;
      actorId?: string | null;
      attachments: Array<{
        __typename?: 'Attachment';
        actorId: string;
        contentLength: any;
        contentType: string;
        createdOn: string;
        id: string;
        md5Checksum: string;
        name: string;
      }>;
      comments: Array<{
        __typename?: 'SofEventNote';
        comment: string;
        createdAt: string;
        createdBy: string;
        id: string;
      }>;
      remarks: Array<{
        __typename?: 'SofEventRemarkResponseDto';
        actorId: string;
        actorName: string;
        createdOn: string;
        persona: string;
        text: string;
        id: any;
      }>;
    }>;
    parameters: {
      __typename?: 'Parameters';
      cargoQuantityActual?: any | null;
      cargoQuantityNominal?: any | null;
      inboundTugsDetails?: string | null;
      inboundTugsOrdered?: number | null;
      inboundTugsUsed?: number | null;
      outboundTugsDetails?: string | null;
      outboundTugsOrdered?: number | null;
      outboundTugsUsed?: number | null;
      pilotsIn?: number | null;
      pilotsOut?: number | null;
    };
    attachments: Array<{
      __typename?: 'Attachment';
      contentLength: any;
      contentType: string;
      createdOn: string;
      id: string;
      name: string;
      actorId: string;
    }>;
    comments: Array<{
      __typename?: 'SofComment';
      comment: string;
      involvement: Involvement;
      createdAt: string;
    }>;
    collaborators: Array<{
      __typename?: 'Collaborator';
      hasBeenOpened: boolean;
      involvement: Involvement;
    }>;
    remarks: Array<{
      __typename?: 'SofRemarkResponseDto';
      actorId: string;
      text: string;
      id: any;
      createdBy: string;
      timestamp: string;
      code?: string | null;
      actorName: string;
    }>;
  }>;
};

export type GetEventTemplatesQueryVariables = Exact<{
  portCallId: Scalars['ID']['input'];
}>;

export type GetEventTemplatesQuery = {
  __typename?: 'Query';
  getEventTemplates: Array<{
    __typename?: 'GroupedEventTemplates';
    group: string;
    eventTemplates: Array<{
      __typename?: 'EventTemplate';
      code: string;
      description: string;
      mandatory: boolean;
    }>;
  }>;
};

export type GetSuggestedEventTemplatesQueryVariables = Exact<{
  portCallId: Scalars['ID']['input'];
}>;

export type GetSuggestedEventTemplatesQuery = {
  __typename?: 'Query';
  getSuggestedEventTemplates: Array<{
    __typename?: 'EventTemplate';
    code: string;
    description: string;
    mandatory: boolean;
  }>;
};

export type GetEventCommentsQueryVariables = Exact<{
  eventId: Scalars['ID']['input'];
  portCallId: Scalars['ID']['input'];
}>;

export type GetEventCommentsQuery = {
  __typename?: 'Query';
  getEventComments: Array<{
    __typename?: 'SofEventNote';
    comment: string;
    createdAt: string;
    createdBy: string;
  }>;
};

export type GetActivityTimelineQueryVariables = Exact<{
  portCallId: Scalars['ID']['input'];
}>;

export type GetActivityTimelineQuery = {
  __typename?: 'Query';
  getActivityTimeline: Array<{
    __typename?: 'SofEventAction';
    actor: string;
    timestampInPortLocalTime: string;
    type: SofEventActionType;
    description: string;
    id: any;
  }>;
};

export type GetSuggestedRemarksQueryVariables = Exact<{
  portCallId: Scalars['ID']['input'];
}>;

export type GetSuggestedRemarksQuery = {
  __typename?: 'Query';
  getSuggestedRemarks: Array<{
    __typename?: 'SofRemarkSuggestion';
    code: string;
    id: any;
    isEditable: boolean;
    isMandatory: boolean;
    text: string;
  }>;
};

export type AddEventMutationVariables = Exact<{
  addEventRequestDto: AddEventRequestDto;
}>;

export type AddEventMutation = {
  __typename?: 'Mutation';
  addEvent: {
    __typename?: 'Sof';
    portCallId: string;
    status: SofStatus;
    error?: {
      __typename?: 'Errors';
      eventError?: boolean | null;
      eventPlaceHolderError?: boolean | null;
      parameterError?: boolean | null;
      remarkError?: boolean | null;
      inboundTugsOrdered?: boolean | null;
      inboundTugsUsed?: boolean | null;
      outboundTugsOrdered?: boolean | null;
      outboundTugsUsed?: boolean | null;
      pilotsIn?: boolean | null;
      pilotsOut?: boolean | null;
      cargoQuantityNominal?: boolean | null;
      cargoQuantityActual?: boolean | null;
    } | null;
  };
};

export type AddParametersMutationVariables = Exact<{
  addParametersRequestDto: AddParametersRequestDto;
}>;

export type AddParametersMutation = {
  __typename?: 'Mutation';
  addParameters: { __typename?: 'Sof'; portCallId: string };
};

export type EditEventMutationVariables = Exact<{
  editEventRequestDto: EditEventRequestDto;
}>;

export type EditEventMutation = {
  __typename?: 'Mutation';
  editEvent: { __typename?: 'Sof'; portCallId: string };
};

export type DeleteEventMutationVariables = Exact<{
  portCallId: Scalars['ID']['input'];
  eventId: Scalars['ID']['input'];
  actor: Scalars['String']['input'];
}>;

export type DeleteEventMutation = {
  __typename?: 'Mutation';
  deleteEvent: { __typename?: 'SofEvent'; id: string; templateCode: string };
};

export type AddEventCommentMutationVariables = Exact<{
  addEventCommentRequestDto: AddEventCommentRequestDto;
}>;

export type AddEventCommentMutation = {
  __typename?: 'Mutation';
  addEventComment: {
    __typename?: 'SofEventNote';
    createdAt: string;
    comment: string;
  };
};

export type AddSofCommentMutationVariables = Exact<{
  addSofCommentRequestDto: AddSofCommentRequestDto;
}>;

export type AddSofCommentMutation = {
  __typename?: 'Mutation';
  addSofComment: Array<{
    __typename?: 'SofComment';
    comment: string;
    createdAt: string;
    involvement: Involvement;
  }>;
};

export type AddRemarksOnEventMutationVariables = Exact<{
  addEventRemarksRequestDto: AddEventRemarksRequestDto;
}>;

export type AddRemarksOnEventMutation = {
  __typename?: 'Mutation';
  addRemarksOnEvent: {
    __typename?: 'SofEventRemarkResponseDto';
    actorId: string;
    persona: string;
    text: string;
    createdOn: string;
  };
};

export type EditEventRemarksMutationVariables = Exact<{
  editEventRemarksRequestDto: EditEventRemarksRequestDto;
}>;

export type EditEventRemarksMutation = {
  __typename?: 'Mutation';
  editEventRemarks: {
    __typename?: 'SofEventRemarkResponseDto';
    id: any;
    actorId: string;
    persona: string;
    text: string;
    createdOn: string;
  };
};

export type DeleteEventRemarkMutationVariables = Exact<{
  portCallId: Scalars['ID']['input'];
  sofEventRemarkId: Scalars['ID']['input'];
}>;

export type DeleteEventRemarkMutation = {
  __typename?: 'Mutation';
  deleteEventRemark: { __typename?: 'SofEventRemarkResponseDto'; id: any };
};

export type AddGeneralRemarksMutationVariables = Exact<{
  addGeneralRemarksRequestDto: AddGeneralRemarksRequestDto;
}>;

export type AddGeneralRemarksMutation = {
  __typename?: 'Mutation';
  addGeneralRemarks: {
    __typename?: 'Sof';
    portCallId: string;
    remarks: Array<{
      __typename?: 'SofRemarkResponseDto';
      id: any;
      text: string;
    }>;
  };
};

export type EditSofRemarkMutationVariables = Exact<{
  editSofRemarkRequestDto: EditSofRemarkRequestDto;
}>;

export type EditSofRemarkMutation = {
  __typename?: 'Mutation';
  editSofRemark: {
    __typename?: 'SofRemarkResponseDto';
    id: any;
    text: string;
    actorId: string;
    createdBy: string;
    code?: string | null;
    timestamp: string;
  };
};

export type DeleteRemarkMutationVariables = Exact<{
  portCallId: Scalars['ID']['input'];
  remarkId: Scalars['ID']['input'];
}>;

export type DeleteRemarkMutation = {
  __typename?: 'Mutation';
  deleteRemark: { __typename?: 'Sof'; portCallId: string; status: SofStatus };
};

export const GetSofStatusesDocument = `
    query getSofStatuses($ids: [ID!]!) {
  getSofsForPortCallIds(ids: $ids) {
    portCallId
    status
  }
}
    `;
export const GetSofsDocument = `
    query getSofs($ids: [ID!]!) {
  getSofsForPortCallIds(ids: $ids) {
    portCallId
    status
    numberOfResendsToMaster
    events {
      timestamp
      creator
      involvement
      description
      editable
      templateCode
      id
      actorId
      attachments {
        actorId
        contentLength
        contentType
        createdOn
        id
        md5Checksum
        name
      }
      comments {
        comment
        createdAt
        createdBy
        id
      }
      remarks {
        actorId
        actorName
        createdOn
        persona
        text
        id
      }
    }
    parameters {
      cargoQuantityActual
      cargoQuantityNominal
      inboundTugsDetails
      inboundTugsOrdered
      inboundTugsUsed
      outboundTugsDetails
      outboundTugsOrdered
      outboundTugsUsed
      pilotsIn
      pilotsOut
    }
    attachments {
      contentLength
      contentType
      createdOn
      id
      name
      actorId
    }
    comments {
      comment
      involvement
      createdAt
    }
    signatureTimestamp
    collaborators {
      hasBeenOpened
      involvement
    }
    remarks {
      actorId
      text
      id
      createdBy
      timestamp
      code
      actorName
    }
  }
}
    `;
export const GetEventTemplatesDocument = `
    query getEventTemplates($portCallId: ID!) {
  getEventTemplates(portCallId: $portCallId) {
    group
    eventTemplates {
      code
      description
      mandatory
    }
  }
}
    `;
export const GetSuggestedEventTemplatesDocument = `
    query getSuggestedEventTemplates($portCallId: ID!) {
  getSuggestedEventTemplates(portCallId: $portCallId) {
    code
    description
    mandatory
  }
}
    `;
export const GetEventCommentsDocument = `
    query getEventComments($eventId: ID!, $portCallId: ID!) {
  getEventComments(eventId: $eventId, portCallId: $portCallId) {
    comment
    createdAt
    createdBy
  }
}
    `;
export const GetActivityTimelineDocument = `
    query getActivityTimeline($portCallId: ID!) {
  getActivityTimeline(portCallId: $portCallId) {
    actor
    timestampInPortLocalTime
    type
    description
    id
  }
}
    `;
export const GetSuggestedRemarksDocument = `
    query getSuggestedRemarks($portCallId: ID!) {
  getSuggestedRemarks(portCallId: $portCallId) {
    code
    id
    isEditable
    isMandatory
    text
  }
}
    `;
export const AddEventDocument = `
    mutation addEvent($addEventRequestDto: AddEventRequestDto!) {
  addEvent(addEventRequestDto: $addEventRequestDto) {
    portCallId
    status
    error {
      eventError
      eventPlaceHolderError
      parameterError
      remarkError
      inboundTugsOrdered
      inboundTugsUsed
      outboundTugsOrdered
      outboundTugsUsed
      pilotsIn
      pilotsOut
      cargoQuantityNominal
      cargoQuantityActual
    }
  }
}
    `;
export const AddParametersDocument = `
    mutation addParameters($addParametersRequestDto: AddParametersRequestDto!) {
  addParameters(addParametersRequestDto: $addParametersRequestDto) {
    portCallId
  }
}
    `;
export const EditEventDocument = `
    mutation editEvent($editEventRequestDto: EditEventRequestDto!) {
  editEvent(editEventRequestDto: $editEventRequestDto) {
    portCallId
  }
}
    `;
export const DeleteEventDocument = `
    mutation deleteEvent($portCallId: ID!, $eventId: ID!, $actor: String!) {
  deleteEvent(portCallId: $portCallId, eventId: $eventId, actor: $actor) {
    id
    templateCode
  }
}
    `;
export const AddEventCommentDocument = `
    mutation addEventComment($addEventCommentRequestDto: AddEventCommentRequestDto!) {
  addEventComment(addEventCommentRequestDto: $addEventCommentRequestDto) {
    createdAt
    comment
  }
}
    `;
export const AddSofCommentDocument = `
    mutation addSofComment($addSofCommentRequestDto: AddSofCommentRequestDto!) {
  addSofComment(addSofCommentRequestDto: $addSofCommentRequestDto) {
    comment
    createdAt
    involvement
  }
}
    `;
export const AddRemarksOnEventDocument = `
    mutation addRemarksOnEvent($addEventRemarksRequestDto: AddEventRemarksRequestDto!) {
  addRemarksOnEvent(addEventRemarksRequestDto: $addEventRemarksRequestDto) {
    actorId
    persona
    text
    createdOn
  }
}
    `;
export const EditEventRemarksDocument = `
    mutation editEventRemarks($editEventRemarksRequestDto: EditEventRemarksRequestDto!) {
  editEventRemarks(editEventRemarksRequestDto: $editEventRemarksRequestDto) {
    id
    actorId
    persona
    text
    createdOn
  }
}
    `;
export const DeleteEventRemarkDocument = `
    mutation deleteEventRemark($portCallId: ID!, $sofEventRemarkId: ID!) {
  deleteEventRemark(portCallId: $portCallId, sofEventRemarkId: $sofEventRemarkId) {
    id
  }
}
    `;
export const AddGeneralRemarksDocument = `
    mutation addGeneralRemarks($addGeneralRemarksRequestDto: AddGeneralRemarksRequestDto!) {
  addGeneralRemarks(addGeneralRemarksRequestDto: $addGeneralRemarksRequestDto) {
    portCallId
    remarks {
      id
      text
    }
  }
}
    `;
export const EditSofRemarkDocument = `
    mutation editSofRemark($editSofRemarkRequestDto: EditSofRemarkRequestDto!) {
  editSofRemark(editSofRemarkRequestDto: $editSofRemarkRequestDto) {
    id
    text
    actorId
    createdBy
    code
    timestamp
  }
}
    `;
export const DeleteRemarkDocument = `
    mutation deleteRemark($portCallId: ID!, $remarkId: ID!) {
  deleteRemark(portCallId: $portCallId, remarkId: $remarkId) {
    portCallId
    status
  }
}
    `;

const injectedRtkApi = sofBaseGraphqlApi.injectEndpoints({
  endpoints: (build) => ({
    getSofStatuses: build.query<
      GetSofStatusesQuery,
      GetSofStatusesQueryVariables
    >({
      query: (variables) => ({ document: GetSofStatusesDocument, variables })
    }),
    getSofs: build.query<GetSofsQuery, GetSofsQueryVariables>({
      query: (variables) => ({ document: GetSofsDocument, variables })
    }),
    getEventTemplates: build.query<
      GetEventTemplatesQuery,
      GetEventTemplatesQueryVariables
    >({
      query: (variables) => ({ document: GetEventTemplatesDocument, variables })
    }),
    getSuggestedEventTemplates: build.query<
      GetSuggestedEventTemplatesQuery,
      GetSuggestedEventTemplatesQueryVariables
    >({
      query: (variables) => ({
        document: GetSuggestedEventTemplatesDocument,
        variables
      })
    }),
    getEventComments: build.query<
      GetEventCommentsQuery,
      GetEventCommentsQueryVariables
    >({
      query: (variables) => ({ document: GetEventCommentsDocument, variables })
    }),
    getActivityTimeline: build.query<
      GetActivityTimelineQuery,
      GetActivityTimelineQueryVariables
    >({
      query: (variables) => ({
        document: GetActivityTimelineDocument,
        variables
      })
    }),
    getSuggestedRemarks: build.query<
      GetSuggestedRemarksQuery,
      GetSuggestedRemarksQueryVariables
    >({
      query: (variables) => ({
        document: GetSuggestedRemarksDocument,
        variables
      })
    }),
    addEvent: build.mutation<AddEventMutation, AddEventMutationVariables>({
      query: (variables) => ({ document: AddEventDocument, variables })
    }),
    addParameters: build.mutation<
      AddParametersMutation,
      AddParametersMutationVariables
    >({
      query: (variables) => ({ document: AddParametersDocument, variables })
    }),
    editEvent: build.mutation<EditEventMutation, EditEventMutationVariables>({
      query: (variables) => ({ document: EditEventDocument, variables })
    }),
    deleteEvent: build.mutation<
      DeleteEventMutation,
      DeleteEventMutationVariables
    >({
      query: (variables) => ({ document: DeleteEventDocument, variables })
    }),
    addEventComment: build.mutation<
      AddEventCommentMutation,
      AddEventCommentMutationVariables
    >({
      query: (variables) => ({ document: AddEventCommentDocument, variables })
    }),
    addSofComment: build.mutation<
      AddSofCommentMutation,
      AddSofCommentMutationVariables
    >({
      query: (variables) => ({ document: AddSofCommentDocument, variables })
    }),
    addRemarksOnEvent: build.mutation<
      AddRemarksOnEventMutation,
      AddRemarksOnEventMutationVariables
    >({
      query: (variables) => ({ document: AddRemarksOnEventDocument, variables })
    }),
    editEventRemarks: build.mutation<
      EditEventRemarksMutation,
      EditEventRemarksMutationVariables
    >({
      query: (variables) => ({ document: EditEventRemarksDocument, variables })
    }),
    deleteEventRemark: build.mutation<
      DeleteEventRemarkMutation,
      DeleteEventRemarkMutationVariables
    >({
      query: (variables) => ({ document: DeleteEventRemarkDocument, variables })
    }),
    addGeneralRemarks: build.mutation<
      AddGeneralRemarksMutation,
      AddGeneralRemarksMutationVariables
    >({
      query: (variables) => ({ document: AddGeneralRemarksDocument, variables })
    }),
    editSofRemark: build.mutation<
      EditSofRemarkMutation,
      EditSofRemarkMutationVariables
    >({
      query: (variables) => ({ document: EditSofRemarkDocument, variables })
    }),
    deleteRemark: build.mutation<
      DeleteRemarkMutation,
      DeleteRemarkMutationVariables
    >({
      query: (variables) => ({ document: DeleteRemarkDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const {
  useGetSofStatusesQuery,
  useLazyGetSofStatusesQuery,
  useGetSofsQuery,
  useLazyGetSofsQuery,
  useGetEventTemplatesQuery,
  useLazyGetEventTemplatesQuery,
  useGetSuggestedEventTemplatesQuery,
  useLazyGetSuggestedEventTemplatesQuery,
  useGetEventCommentsQuery,
  useLazyGetEventCommentsQuery,
  useGetActivityTimelineQuery,
  useLazyGetActivityTimelineQuery,
  useGetSuggestedRemarksQuery,
  useLazyGetSuggestedRemarksQuery,
  useAddEventMutation,
  useAddParametersMutation,
  useEditEventMutation,
  useDeleteEventMutation,
  useAddEventCommentMutation,
  useAddSofCommentMutation,
  useAddRemarksOnEventMutation,
  useEditEventRemarksMutation,
  useDeleteEventRemarkMutation,
  useAddGeneralRemarksMutation,
  useEditSofRemarkMutation,
  useDeleteRemarkMutation
} = injectedRtkApi;
